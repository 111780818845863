import styled from 'styled-components/macro'
import Macy from 'macy'
import { motion } from 'framer-motion'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import queries from 'styles/breakpoints'
import data from 'data/data.json'
import GalleryCard from 'components/molecules/GalleryCard'
import { pageAnimation, galleryAnimation } from 'utils/animations'
import { resetSlider } from 'store/slidesSlice'

const Wrapper = styled(motion.main)`
  padding: 1.5rem;
  margin: 0 auto;
  max-width: var(--max-width);
  @media ${queries.tabletUp} {
    padding-top: 2.5rem;
  }
`
const ContainerCenter = styled(motion.div)`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding-top: 4.75rem;
  padding-bottom: 4.75rem;
  h1 {
    line-height: 30px;
    font-size: 2.5rem;
    color: var(--color-black);
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0.5rem;

    @media ${queries.tabletUp} {
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
    }
  }

  p {
    line-height: 30px;
    font-size: 0.9375rem;
    letter-spacing: 3px;
    color: var(--color-grey-1);
  }
`

const Container = styled(motion.div)`
  padding-top: 5.5rem;
  padding-bottom: 8.25rem;
  display: flex;
  flex-direction: column;
  align-item: center;
  @media ${queries.laptopUp} {
    flex-direction: row;
  }
  h1 {
    font-size: 1.95rem;
    color: var(--color-black);
    font-weight: bold;
    line-height: 1;
    margin-bottom: 0.5rem;

    @media ${queries.tabletUp} {
      font-size: 2.5rem;
      margin-bottom: 1.5rem;
    }
  }

  p {
    line-height: 30px;
    font-size: 0.9375rem;
    letter-spacing: 3px;
    color: var(--color-grey-1);
  }
`

const ColLeft = styled(motion.div)`
  flex: 1;
  padding-bottom: 1rem;
`

const ColRight = styled(motion.div)`
  flex: 4;
`

const Iframe = styled(motion.iframe)`
  border: none;
  with: 420px;
  height: 815px;
`

const List = styled(motion.ul)`
  margin: 0;
  img {
    width: 100%;
  }
`

const Footer = styled(motion.footer)`
  padding-top: 1.5rem;
  text-align: center;
  border-top: 2px rgba(200, 200, 200, 0.9) solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
`

const macyOptions = {
  container: '#macy-grid',
  trueOrder: true,
  mobileFirst: true,
  margin: 24,
  columns: 1,
  breakAt: {
    1000: 4,
    650: {
      margin: 40,
      columns: 2,
    },
  },
}

const Gallery = (): JSX.Element => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetSlider())
  }, [dispatch])

  useEffect(() => {
    new Macy(macyOptions)
  }, [])

  return (
    <Wrapper exit="exit" variants={pageAnimation} initial="hide" animate="show">
      <h1 className="sr-only">Main Gallery</h1>
      <ContainerCenter>
        <h1>About Our work</h1>
        <p>{`You can discover in a flash the ideas of our customers that we have brought to life. Thanks to the co-operation with our customers, we were able to carry out beautiful projects with an excellent UX/UI design to leave a remarkable impression.`}</p>
      </ContainerCenter>
      <ContainerCenter>
        <Iframe src="https://www.youtube.com/embed/_JisZNurNgs"></Iframe>
      </ContainerCenter>
      <Container>
        <ColLeft>
          <h1>challenges</h1>
        </ColLeft>
        <ColRight>
          <p>
            {`As a Flutter and ReactJS developer, I have faced complex challenges in developing cross-platform applications and staying up-to-date with the latest technologies. Collaborating with other developers and optimizing application performance are also crucial. Despite these challenges, I continuously learn and work hard to deliver high-quality applications to clients.`}
          </p>
        </ColRight>
      </Container>
      <List
        id="macy-grid"
        initial="hide"
        animate="show"
        variants={galleryAnimation}
      >
        {data.map((painting, index) => {
          return <GalleryCard {...painting} id={index} key={index} />
        })}
      </List>
    </Wrapper>
  )
}

export default Gallery
