import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import LogoIcon from 'assets/shared/logo_dark.png'
import queries from 'styles/breakpoints'

const StyledLink = styled.a`
  line-height: 1;
`

const Wrapper = styled.div`
  width: 5.0625rem;
  svg {
    width: 100%;
    height: auto;
  }

  @media ${queries.laptopUp} {
    width: 10.625rem;
  }
`

const Image = styled.img`
  width: 7.0625rem;
`

const Logo = (): JSX.Element => {
  return (
    <StyledLink href="https://myheart.ma">
      <Wrapper>
        <Image src={LogoIcon} alt="Logo - home" />
      </Wrapper>
    </StyledLink>
  )
}

export default Logo
